<template>
    <b-card no-body class="card-developer-meetup">
      <div class="bg-light-primary rounded-top text-center">
        <b-img
          :src="require('@/assets/images/illustration/email.svg')"
          alt="Meeting Pic"
          height="170"
        />
      </div>
      <b-card-body>
        <!-- metting header -->
        <validation-observer ref="simple">
        <b-card-code>
          <b-form>
            <b-row>
              <b-col md="" xl="2">
                <validation-provider #default="{ errors }" rules="required">
                  <b-form-group
                    label-for="Inputperson"
                    :state="errors.length > 0 ? false : null"
                  >
                    <label>الشخص المدخل</label>
                    <b-form-input
                      v-model="selectedRel"
                     
                  readonly
                    
                    />
                  </b-form-group>
                  <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </validation-provider>
              </b-col>
              <b-col md="12" xl="2">
                <b-form-group label-for="name">
                  <validation-provider #default="{ errors }" name="CardNumber" rules="required">
                    <label>ادخل رقم البطاقة</label>
  
                    <b-form-input
                      id="helperInput"
                      :state="errors.length > 0 ? false : null"
                   @input="getBeneficiaryByCardNumber(CardNumber)"
                      v-model="CardNumber"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="12" xl="2" v-if="selectedRel==2">
                <b-form-group label-for="name">
                  <validation-provider #default="{ errors }" name="name" rules="required">
                    <label>الاسم</label>
  
                    <b-form-input
                      id="helperInput"
                      :state="errors.length > 0 ? false : null"
                   
                      v-model="beneficaryName"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="12" xl="2" v-if="selectedRel==2">
                <b-form-group label-for="name">
                  <validation-provider #default="{ errors }" name="Number" rules="required">
                    <label>رقم الهاتف</label>
  
                    <b-form-input
                      id="helperInput"
                      :state="errors.length > 0 ? false : null"
                   type="number"
                      v-model="beneficaryNumber"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
  
  
              <b-col md="" xl="2" >
                <validation-provider #default="{ errors }" rules="required">
                  <b-form-group
                    label-for="Inputperson"
                    :state="errors.length > 0 ? false : null"
                  >
                    <label>الافراد</label>
                    <v-select
                      v-model="beneficaryId"
                      :reduce="(val) => val.id"
                      label="first_name"
                  
                      :options="beneficaryList"
                    >
                  
                    <template #no-options="{ search, searching, loading }" v-if="!getBeneficiaryByCard">
                    ادخل رقم البطاقة اولا
                    </template>
                    <template #no-options="{ search, searching, loading }" v-else>
                     Loading ...
                    </template>
                  </v-select>
                  </b-form-group>
                  <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </validation-provider>
              </b-col>
            
              <!-- <div > -->
                 <b-col md="" xl="2" >
                <validation-provider #default="{ errors }" rules="">
                  <b-form-group
                    label-for="Inputperson"
                    :state="errors.length > 0 ? false : null"
                  >
                    <label>القسم</label>
                    <v-select
                      v-model="service"
                      disabled
                      :reduce="(val) => val.id"
                  label="name"
                      :options="serviceList"
                    >
                  
                   
                  </v-select>
                  </b-form-group>
                  <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </validation-provider>
              </b-col>
            </b-row>
            <div class="meetup-header d-flex align-items-center">
      
      <div class="my-auto">
        <b-card-title class="mb-2"> </b-card-title>
        <!-- <b-card-text class="mb-0"> -->
        <label> ماهي المشكلة التي تواجهك </label>
  
        <!-- </b-card-text> -->
      </div>
    </div>
    <!--/ metting header -->
    <validation-provider #default="{ errors }" rules="required">
    <b-form-group label-for="notes" :state="errors.length > 0 ? false : null">
      <b-form-textarea
        id="textarea-default"
        v-model="problemBody"
        readonly
        rows="2"
      />
    </b-form-group>
    <small class="text-danger">{{ errors[0] }}</small>
  </validation-provider>
          </b-form>
  
        
        </b-card-code>
        <br />
    
        
        <b-button variant="purple" @click="save"    v-if="!isLoading">
          <feather-icon icon="PlusIcon" class="mr-50" />
          <span class="align-middle">استكمال الشكوى</span>
        </b-button>
        <b-button v-if="isLoading"  variant="purple" disabled class="mr-1">
                Loading...
             
                </b-button>
      </validation-observer>
      </b-card-body>
      <b-col class="col-2 text-align-start mb-2">
  
        <b-button v-ripple.400="'rgba(113, 102, 240, 0.15)'" variant="outline-primary" @click="$router.go(-1)">
          <span class="align-middle">رجوع</span>
        </b-button>
      </b-col>
  
    </b-card>
  
  </template>
  
  <script>
  import vSelect from "vue-select";
  import { required, max, is_earlier } from "@validations";
  import BCardCode from "@core/components/b-card-code/BCardCode.vue";
  import { ValidationProvider, ValidationObserver } from "vee-validate";
  import {
    BCard,
    BFormGroup,
  
    BImg,
    BButton,
    BCardBody,
    BCardText,
    BCardTitle,
    BMedia,
    BMediaAside,
    BAvatar,
    BAvatarGroup,
    VBTooltip,
    BInputGroupPrepend,
    BInputGroup,
    BFormInput,
    BFormTextarea,
    BRow,
    BCol,
    BForm,
    BMediaBody,
    BFormInvalidFeedback,
  } from "bootstrap-vue";
  import Ripple from "vue-ripple-directive";
  /* eslint-disable global-require */
  export default {
    components: {
      required,
      BFormInvalidFeedback,
      ValidationProvider, ValidationObserver,
      BCard,
      BRow,
      BFormInput,
    BFormTextarea,
    BCol,
      vSelect,
      BForm,
      BInputGroupPrepend,
    BInputGroup,
      BCardCode,
      BFormGroup,
      BFormTextarea,
      BButton,
      BImg,
      BCardBody,
      BCardText,
      BCardTitle,
      BMedia,
      BMediaAside,
      BAvatar,
      BAvatarGroup,
      BMediaBody,
    },
    directives: {
      Ripple,
    },
    data() {
      return {
        userName: "",
        problemBody: "",
        beneficaryName:'',
        beneficaryNumber:'',
        isLoading:false,
        selectedRel:'شخص مسجل ',
        CardNumber:'',
        beneficaryId:'',
        service:'',
        serviceList:[],
        beneficaryList:[],
        getBeneficiaryByCard:false,
        optionPerson:[
          {label:'شخص مسجل ', value:1},
          {label:'شخص غير مسجل ', value:2}
        ]
      };
    },
  
    directives: {
      "b-tooltip": VBTooltip,
    },
  
    created() {
      this.userName = localStorage.getItem("userName");
      let url = `/api/v1/complains/${this.$route.params.id}`;

this.$http.get(url).then((res) => {
  //console.log(res.data.data);
  this.problemBody=res.data.data.body
  this.service=res.data.data.main_service_id


});
      this.$http
          .get(`/api/v1/main_services`)
          .then((res) => {
          //console.log(res.data.data)
          this.serviceList=res.data.data
          })
          .catch((error) => {
            //console.log(error.response.data.message);
            this.$swal({
              title: `${error.response.data.message}`,
  
              icon: "success",
              confirmButtonText: "موافق",
              customClass: {
                confirmButton: "btn btn-primary",
              },
              buttonsStyling: false,
            });
          });
    },
    methods: {
      save() {
        this.$refs.simple
            .validate()
            .then((success) => {
              if (success) {
                this.isLoading=true
        let user = JSON.parse(localStorage.getItem("userData"));
        let data = {
    
          user_id: user.id,
          person_id:this.beneficaryId,
       
        };
        //console.log(data);
  
        this.$http
          .put(`/api/v1/complains/${this.$route.params.id}`, data)
          .then((res) => {
            this.isLoading=false
          
            this.$swal({
              title: "تم استكمال الشكوى بنجاح",
  
              icon: "success",
              confirmButtonText: "موافق",
              customClass: {
                confirmButton: "btn btn-primary",
              },
              buttonsStyling: false,
            });
          })
          .catch((error) => {
            this.isLoading=false
            this.$swal({
              title: `${error.response.data.message}`,
  
              icon: "success",
              confirmButtonText: "موافق",
              customClass: {
                confirmButton: "btn btn-primary",
              },
              buttonsStyling: false,
            });
          });
        }})
      },
      getBeneficiaryByCardNumber(id){
  this.getBeneficiaryByCard=true
  this.$http
          .get(`/api/v1/get-persons-by-card-number/${id}`)
          .then((res) => {
          //console.log(res.data.data)
          this.beneficaryList=res.data.data
          })
          .catch((error) => {
            //console.log(error.response.data.message);
            this.$swal({
              title: `${error.response.data.message}`,
  
              icon: "success",
              confirmButtonText: "موافق",
              customClass: {
                confirmButton: "btn btn-primary",
              },
              buttonsStyling: false,
            });
          });
      }
    },
  };
  </script>
  
  <style scoped>
  label {
    color: #7e7a9f;
    font-weight: bold;
    font-family: "Cairo", sans-serif;
  }
  .btn-purple {
    color: white;
    width: 200px;
    background-color: #00468e !important;
  }
  </style>
  